"use client"

import React from "react"
import { useTranslations } from "next-intl"
import { Box, Typography, AdviqoLink, Paper } from "../_common"
import MedalIcon from "../_common/QuesticoIcon/MedalIcon"
import SecureIcon from "../_common/QuesticoIcon/SecureIcon"
import LockIcon from "../_common/QuesticoIcon/LockIcon"
import classes from "./TrustSection.module.scss"

interface ITrustItemProps {
  icon: React.ReactElement
  title: string
  children: React.ReactNode
}

interface ITrustSection {
  classNames?: string
}

const TrustItem: React.FC<ITrustItemProps> = ({ icon, title, children }) => {
  return (
    <Box className={classes.trustItem}>
      <Box className={classes.iconWrapper}>{icon}</Box>
      <Box>
        <Typography gutterBottom component="h6" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.body}>{children}</Typography>
      </Box>
    </Box>
  )
}

const TrustSection: React.FC<ITrustSection> = ({ classNames }) => {
  const translate = useTranslations("trustSection")
  return (
    <Paper rootClassName={classNames} bodyClassName={classes.paperBody}>
      <Box component="ul" className={classes.list}>
        <Box component="li" className={classes.listItem}>
          <TrustItem icon={<MedalIcon />} title={translate("firstItem.title")}>
            {translate("firstItem.description")}
          </TrustItem>
        </Box>
        <Box component="li" className={classes.listItem}>
          <TrustItem
            icon={<SecureIcon />}
            title={translate("secondItem.title")}
          >
            {translate("secondItem.description")}
          </TrustItem>
        </Box>
        <Box component="li" className={classes.listItem}>
          <TrustItem icon={<LockIcon />} title={translate("thirdItem.title")}>
            {translate.rich("thirdItem.description", {
              linkItem: (chunks) => (
                <AdviqoLink to="/datenschutz">{chunks}</AdviqoLink>
              ),
            })}
          </TrustItem>
        </Box>
      </Box>
    </Paper>
  )
}

export default TrustSection
