import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const LockIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  className,
}) => {
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7236_64110)">
            <path
              d="M19.2666 9.71045H4.7334V21.2989H19.2666V9.71045Z"
              stroke={color}
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="square"
            />
            <path
              d="M11.997 2.70654C14.7898 2.70654 17.0605 4.89422 17.0605 7.58501V9.7105H6.93359V7.58501C6.93359 4.89422 9.20422 2.70654 11.997 2.70654Z"
              stroke={color}
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="square"
            />
            <path
              d="M11.9971 15.6179C12.7942 15.6179 13.4404 14.9953 13.4404 14.2273C13.4404 13.4593 12.7942 12.8367 11.9971 12.8367C11.1999 12.8367 10.5537 13.4593 10.5537 14.2273C10.5537 14.9953 11.1999 15.6179 11.9971 15.6179Z"
              stroke={color}
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="square"
            />
            <path
              d="M11.9971 15.6123V18.1674"
              stroke={color}
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="square"
            />
          </g>
          <defs>
            <clipPath id="clip0_7236_64110">
              <rect
                width="16"
                height="20"
                fill="white"
                transform="translate(4 2)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            color={color}
            d="M9.14014 11.76L11.5701 14.1L15.7101 9.28003"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            color={color}
            d="M12.43 3.21C12.14 2.93 11.72 2.93 11.43 3.21C9.72 4.73 8.43 5.14001 6.86 5.00001C5.86 4.86001 5 5.55 5 6.52V12.45C5 14.38 5.86 16.17 7.43 17.42L12 21.01L16.57 17.42C18.14 16.18 19 14.39 19 12.45V6.52C19 5.55 18.14 4.86001 17.14 5.00001C15.57 5.28001 14.14 4.86 12.43 3.21Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default LockIcon
