"use client"

import React, { ComponentProps } from "react"
import { usePathname } from "next/navigation"
import ExpertAds from "../../../ui-lib/Experts/ExpertAds/ExpertAds"
import { isRootDashboard } from "../../../utils/utils"

const ExpertAdsWrapper: React.FC<ComponentProps<typeof ExpertAds>> = (
  props
) => {
  const pathname = usePathname()

  if (!isRootDashboard(pathname)) return null

  return <ExpertAds {...props} />
}

export default ExpertAdsWrapper
