"use client"

import React, { useEffect, useState } from "react"

import { Hidden } from "../_common"
import { useBrowserFeatures } from "../../hooks/browserFeatures"
import MobileNavigation from "./MobileNavigation"
import DesktopNavigation from "./DesktopNavigation"
import { INavigationProps } from "./types"

const NavBar: React.FC<INavigationProps> = (props) => {
  const { isFeatureSupported } = useBrowserFeatures()
  const isMobile = isFeatureSupported("isMobile")
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    if (isMobile) {
      return <MobileNavigation {...props} />
    }

    return <DesktopNavigation {...props} />
  }

  return (
    <>
      <Hidden mdUp>
        <MobileNavigation {...props} />
      </Hidden>
      <Hidden mdDown>
        <DesktopNavigation {...props} />
      </Hidden>
    </>
  )
}

export default NavBar
